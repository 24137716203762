(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("React"), require("mobx"), require("mobxReact"), require("ReactDOM"), require("mobxUtils"), require("sbInternalMsgBus"), require("sbJsExtends"), require("sbLocalizationLib"), require("sbDataLayer"), require("gsap"));
	else if(typeof define === 'function' && define.amd)
		define("sb-responsive-results", ["React", "mobx", "mobxReact", "ReactDOM", "mobxUtils", "sbInternalMsgBus", "sbJsExtends", "sbLocalizationLib", "sbDataLayer", "gsap"], factory);
	else if(typeof exports === 'object')
		exports["sb-responsive-results"] = factory(require("React"), require("mobx"), require("mobxReact"), require("ReactDOM"), require("mobxUtils"), require("sbInternalMsgBus"), require("sbJsExtends"), require("sbLocalizationLib"), require("sbDataLayer"), require("gsap"));
	else
		root["sb-responsive-results"] = factory(root["React"], root["mobx"], root["mobxReact"], root["ReactDOM"], root["mobxUtils"], root["sbInternalMsgBus"], root["sbJsExtends"], root["sbLocalizationLib"], root["sbDataLayer"], root["gsap"]);
})(window, function(__WEBPACK_EXTERNAL_MODULE__0__, __WEBPACK_EXTERNAL_MODULE__1__, __WEBPACK_EXTERNAL_MODULE__4__, __WEBPACK_EXTERNAL_MODULE__8__, __WEBPACK_EXTERNAL_MODULE__9__, __WEBPACK_EXTERNAL_MODULE__11__, __WEBPACK_EXTERNAL_MODULE__12__, __WEBPACK_EXTERNAL_MODULE__13__, __WEBPACK_EXTERNAL_MODULE__33__, __WEBPACK_EXTERNAL_MODULE__34__) {
return 